import Rails from "@rails/ujs";
import { Controller } from "stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = [ "form", "indicator", "saving" ]

  declare readonly formTarget: HTMLFormElement
  declare readonly indicatorTarget: HTMLElement
  declare readonly savingTarget: HTMLElement

  initialize() {
    this.save = debounce(this.save, 1000)
  }

  handleChange() {
    this.indicatorTarget.classList.add('hidden')
    this.savingTarget.classList.remove('hidden')
    this.save()
  }

  submitForm() {
    window.scrollTo(0, 0)
  }

  save() {
    Rails.fire(this.formTarget, 'submit')
  }
}
