import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slide", "knob", "value"];

  declare readonly valueTarget: HTMLInputElement
  declare readonly slideTarget: HTMLElement
  declare readonly knobTarget: HTMLElement

  toggle(event) {
    event.preventDefault;

    if (this.valueTarget.value === "false") {
      this.slideTarget.classList.replace("bg-gray-200", "bg-green-600");
      this.knobTarget.classList.replace("translate-x-0", "translate-x-5");
      this.valueTarget.value = "true";
    } else {
      this.slideTarget.classList.replace("bg-green-600", "bg-gray-200");
      this.knobTarget.classList.replace("translate-x-5", "translate-x-0");
      this.valueTarget.value = "false";
    }
  }
}
