import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "question" ]

  declare readonly questionTarget: HTMLElement

  handleChange() {
    this.questionTarget.classList.remove('text-red-400')
  }
}
