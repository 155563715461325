import Rails from "@rails/ujs";
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form" ]

  declare readonly formTarget: HTMLFormElement

  filter() {
    Rails.fire(this.formTarget, 'submit')
  }
}
