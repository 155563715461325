import { Controller } from "stimulus"
import html2pdf from "html2pdf.js";

export default class extends Controller {
  static targets = [ "export" ]

  declare readonly exportTarget: HTMLElement

  export() {
    const options = {
      margin:       2,
      filename:     'systeem-audit.pdf',
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2 },
    };
    html2pdf().set(options).from(this.exportTarget).save();
  }
}
