import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "label", "list", "field" ]

  declare readonly fieldTarget: HTMLInputElement
  declare readonly listTarget: HTMLElement
  declare readonly labelTarget: HTMLElement

  toggle() {
    this.listTarget.classList.toggle('hidden');
  }

  select(e: { currentTarget: HTMLInputElement }) {
    const value = e.currentTarget.dataset.value
    const label = e.currentTarget.dataset.label
    this.fieldTarget.value = value || ''
    this.labelTarget.innerHTML = label
    this.toggle()
  }
}
