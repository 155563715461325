import { Controller } from "stimulus";

/* Controller to ensure only one element in the radio group is active.
 * The actual element being submitted is handled by the HTML.
*/
export default class extends Controller {
  static targets = ["border"];

  declare readonly borderTargets: [HTMLElement]

  change(event) {
    event.preventDefault;

    this.borderTargets.forEach((label) => {
      if (event.currentTarget.value === label.dataset.value) {
        label.classList.replace("border-transparent", "border-green-500");
      } else {
        label.classList.replace("border-green-500", "border-transparent");
      }
    })
  }
}
