import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "question", "yesToAll" ]

  declare readonly questionTargets: [HTMLElement]
  declare readonly yesToAllTarget: HTMLElement

  initialize() {
    this.handleChange()
  }

  defaultableYeses() {
    const checkedQuestionRefs = this.questionTargets.filter(question => question.checked).map(question => question.dataset.reference)
    return this.questionTargets.filter(question =>
      question.dataset.yes === "true" && checkedQuestionRefs.indexOf(question.dataset.reference) === -1
    )
  }

  handleChange() {
    if (!this.hasYesToAllTarget || this.yesToAllTarget.hidden) return;
    if (this.defaultableYeses().length <= 5) {
      this.yesToAllTarget.hidden = true;
    }
  }

  yesToAll() {
    this.defaultableYeses().forEach(question => question.click());
  }
}
