import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "panel" ]
  static values = { hidden: Boolean }

  declare readonly panelTarget: HTMLInputElement
  declare hiddenValue: Boolean

  show() {
    this.panelTarget.classList.remove('hidden');
    window.setTimeout(() => this.hiddenValue = false, 1000);
  }

  hide() {
    if (!this.hiddenValue) {
      this.panelTarget.classList.add('hidden');
      this.hiddenValue = true;
    }
  }
}
