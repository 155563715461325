import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["selectedFiles", "fileInput", "filesList"];

  declare readonly selectedFilesTarget: HTMLElement
  declare readonly fileInputTarget: HTMLInputElement
  declare readonly filesListTarget: HTMLElement

  update(event) {
    const files = Array.from(this.fileInputTarget.files);

    this.filesListTarget.classList.remove("hidden");
    this.selectedFilesTarget.innerHTML = ''; // Remove any existing files

    files.forEach(file => this.addFile(file));
  }

  addFile(file) {
    const filename = document.createElement("p");
    filename.innerText = file.name;

    this.selectedFilesTarget.appendChild(filename);
  }
}
