import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "mobile", "user" ]

  declare readonly mobileTarget: HTMLElement
  declare readonly userTarget: HTMLElement

  toggleUserMenu() {
    this.userTarget.classList.toggle("h-0");
    this.userTarget.classList.toggle("opacity-0");
  }

  toggleMobileMenu() {
    this.mobileTarget.classList.toggle("h-0");
    this.mobileTarget.classList.toggle("opacity-0");
  }
}
